<template>
  <div
    class=""
  >
    <div class="">
      <b-tabs
        card
      >
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 1"
          @click="$router.push({ name: 'team-performance-individual', params: { id, performance_id, tabIdx: 0 } })"
        />
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 2"
          @click="$router.push({ name: 'team-performance-individual', params: { id, performance_id, tabIdx: 1 } })"
        />
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 3"
          @click="$router.push({ name: 'team-performance-individual', params: { id, performance_id, tabIdx: 2 } })"
        />
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 4"
          @click="$router.push({ name: 'team-performance-individual', params: { id, performance_id, tabIdx: 3 } })"
        />
        <b-tab
          class="p-0"
          :title="$t('Summary Score')"
          @click="$router.push({ name: 'team-performance-individual', params: { id, performance_id, tabIdx: 4 } })"
        />
      </b-tabs>
    </div>
    <!-- START ALL FORM's PART -->

    <div
      v-if="scoreCards.length !== 0"
      class="d-flex"
    >

      <div
        v-for="part in allPart"
        :key="part.id"
        :class="scoreCards.length === 0 ? '' : 'pointer'"
        class="mt-1 mr-1 mb-2"
        :style="part.id === currentPart.id? {'border-bottom': '3.5px solid #FF0000'} : {}"
        @click="scoreCards.length === 0 ? '' :currentPart = part"
      >
        <span :style="[scoreCards.length === 0 ? {color: 'black', opacity: '0.6'} : {color: 'black'}]">{{ $t(part.title) }}</span>
      </div>

    </div>
    <div v-else>
      <!-- No kra -->
    </div>
    <!-- END ALL FORM's PART -->
    <!-- START FORM -->
    <!-- {{ currentPart.id }} -->

    <div
      v-if="scoreCards.length !== 0"
      class="p-1 d-flex flex-column"
      style="background-color: white;"
    >

      <div
        name="fade-custom"
        mode="out-in"
      >
        <div
          v-if="currentPart.id === 'setKra' "
          key="currentPartSet"
          class="d-flex align-items-center justify-content-between"
        >
          <div
            class="formTitle"
          >
            {{ $t ("Performance Planning") }}
          </div>
          <!-- <div
            v-if="isSubmitted"
            class="d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.left="$t('Your planning has been submitted')"
              class="btn btn-success"
              style="pointer: default;"
            >
              Submitted
            </div>
          </div> -->
          <!-- <div
            v-else
            class="d-flex align-items-center justify-content-center"
          >
            <button
              class="btn btn-primary"
              :disabled="scoreCards.length === 0"
              @click="saveKra()"
            >
              {{ $t ("Save Changes") }}
            </button>
            <button
              class="ml-1 btn btn-primary"
              :disabled="scoreCards.length === 0"
              @click="submitKra()"
            >
              {{ $t ("Submit") }}
            </button>
          </div> -->
        </div>
        <div
          v-else-if="currentPart.id === 'review'"
          key="currentPartReview"
          class="d-flex align-items-center justify-content-between"
        >
          <span
            class="formTitle"
          >
            {{ $t ("Performance Review") }}
          </span>
          <!-- cycle_id:{{ performance_id }}
          userID:{{ this.userId }}<br>
          tab:{{ currentPart.id === 'review' ? 'review': 'not review tab' }} -->
          <!-- <div
            v-if="isSubmitted"
            class="d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.left="'Your review has been submitted'"
              class="btn btn-success"
              style="pointer: default;"
            >
              Submitted
            </div>
          </div> -->
          <!-- <div
            v-else
            class="d-flex align-items-center justify-content-center"
          >
            <button
              class="btn btn-primary"
              :disabled="scoreCards.length === 0"
              @click="saveReview()"
            >
              {{ $t ("Save Changes") }}
            </button>
            <button
              :disabled="scoreCards.length === 0"
              class="ml-1 btn btn-primary"
              @click="submitReview()"
            >
              {{ $t ("Submit") }}
            </button>
          </div> -->

        </div>
        <div
          v-else-if="currentPart.id === 'evaluation'"
          key="currentPartEvaluation"
          class="d-flex align-items-center justify-content-between"
        >
          <span
            class="formTitle"
          >
            {{ $t ("Year End Result") }}
          </span>
          <!-- userID:{{ this.userId }}<br>
          tab:{{ currentPart.id === 'evaluation' ? 'evaluation': 'not evaluation tab' }} -->

          <!-- <div
            v-if="isSubmitted && roles.selectedRole !== 4"
            class="d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.left="'Your result has been submitted'"
              class="btn btn-success"
              style="pointer: default;"
            >
              Submitted
            </div>
          </div> -->
          <div
            class="d-flex align-items-center justify-content-center mr-1"
          >
            <!-- {{ isApproveSHR }} {{ roles.selectedRole }} -->
            <div v-if="isSubmitted || (roles.selectedRole === 1 || roles.selectedRole === 3 || ((roles.selectedRole === 4 && (!division || !section)) && isSupervisor === 1) || roles.selectedRole === 5)">
              <b-button
                variant="primary"
                class="saj-button"
                style="margin-right: 10px"
                :style="isApproveSV || isApproveHOD || isApproveSHR ? '' : 'display: none;'"
                @click="toggleInput()"
              >
                {{ disableRating ? $t("Edit") : $t("Cancel") }}
              </b-button>
              <button
                class="btn btn-primary"
                :disabled="scoreCards.length === 0"
                :style="isApproveSV || isApproveHOD || isApproveSHR ? '' : 'display: none;'"
                @click="saveResult(), toggleInput()"
              >
                {{ $t ("Save Changes") }}
              </button>
            </div>

          </div>
        </div>
        <div
          v-if="currentPart.id === 'evaluation'"
          class="row d-flex justify-content-end m-1"
        >
          <!-- Middle Management(3) / Executive(4) -->
          <div
            v-if="grade_set_id === 3 || grade_set_id === 4"
            class="saj-title mt-1 d-flex justify-content-end"
          >
            {{ total_score_exec }}/{{ kra_weightage }} %
          </div>
          <!-- Top Management(1) / Senior Management(2)  -->
          <div
            v-if="grade_set_id === 1 || grade_set_id === 2"
            class="saj-title mt-1 d-flex justify-content-end"
          >
            {{ total_score_top }}/{{ kra_weightage }} %
          </div>
        </div>
      </div>

      <div
        v-if="currentPart.id === 'setKra' && !isSubmitted && !roles.selectedRole === 4"
        class="saj-text btn btn-primary mt-1"
        style="width: 25rem"
        @click="addScoreBoard(
        )"
      >
        {{ $t ("Create Department's Objective / Initiative") }}
      </div>
      <div class="mt-2 d-flex flex-column px-1">
        <template v-if="scoreCards.length > 0">
          <transition-group
            name="list"
            mode="out-in"
          >
            <div
              v-for="(scoreCard, indexScoreBoard) in scoreCards"
              :key="scoreCard.id"
              style="position: relative;background: #F7F7FC;
                  border-radius: 6px;"
              class="mb-1"
            >

              <!-- #REMOVE SPECIFIC SCORE BOARD - START -->
              <transition
                name="zoom-out"
                mode="out-in"
              >

                <template v-if="!isReadOnly && !isSubmitted && isSubmitted">
                  <div
                    key="removeSpecificScoreBoard"
                    style="position: absolute; right: -5px; top: -10px; background-color: white; border-radius: 50%;"
                    @click="removeSpecificScoreBoard(
                      {
                        id:scoreCard.id,
                        index: indexScoreBoard
                      }
                    )"
                  >
                    <feather-icon
                      icon="MinusCircleIcon"
                      class=""
                      style="color: #C5C7CD"
                      size="20"
                    />
                  </div>
                </template>

              </transition>
              <!-- #REMOVE SPECIFIC SCORE BOARD - START -->
              <!-- #SCORE CARD PANEL - START -->
              <div
                class="d-flex align-items-center justify-content-between pointer p-2"
                @click="scoreCard.visible = !scoreCard.visible"
              >
                <span class="saj-text">{{ $t(scoreCard.title) }} {{ indexScoreBoard + 1 }}</span>
                <feather-icon
                  :icon="scoreCard.visible? 'ChevronUpIcon': 'ChevronDownIcon'"
                  size="20"
                  style="color: #6E6B7B;"
                />
              </div>
              <!-- #SCORE CARD PANEL - END -->
              <!-- #SCORE CARD COLLAPSE PANEL - START -->
              <validation-observer ref="validateKRA">
                <b-collapse
                  v-model="scoreCard.visible"
                >

                  <div
                    class="px-1"
                    :class="isReadOnly ? 'pb-2' : ''"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('objective')"
                      rules="required"
                    >
                      <textarea
                        v-model="scoreCard.description"
                        style="width: 100%; min-height: 70px; background:#EEEEEE "
                        :disabled="true"
                        class="custom-formcontrol-none mt-1"
                      />
                      <small
                        class="text-danger"
                        style="font-family: Poppins"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                    <transition-group
                      name="list"
                      mode="out-in"
                    >
                      <div
                        v-for="(KRA, indexKRA) in scoreCard.KRA"
                        :key="KRA.id"
                        style="background: #efedee; border-radius: 6px;"
                        class="p-1 mb-1"
                      >
                        <!-- #KRA PANEL - START -->
                        <div
                          class="d-flex align-items-center justify-content-between pointer"
                          @click="KRA.visible = !KRA.visible"
                        >
                          <span class="saj-text">
                            {{ $t ("KEY RESULT AREA") }}</span>
                          <feather-icon
                            :icon="KRA.visible? 'ChevronUpIcon': 'ChevronDownIcon'"
                            size="20"
                            style="color: #6E6B7B;"
                          />
                        </div>
                        <!-- #KRA PANEL - END -->
                        <!-- #KRA COLLAPSE PANEL - START -->
                        <b-collapse
                          v-model="KRA.visible"
                        >
                          <div class="mt-2 mb-2">
                            <div
                              class="d-flex  justify-content-between align-items-center"
                              style="margin-bottom: 25px;"
                            >
                              <div
                                class="d-flex flex-column"
                                style="width: 100%;"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('KRA')"
                                  rules="required"
                                >
                                  <!-- <br>tab:{{ currentPart.id === 'evaluation' ? 'evaluation': 'not evaluation tab' }} -->
                                  <!-- <br>target ID{{ KRA.target.id }} -->
                                  <!-- {{ KRA }} -->
                                  <textarea
                                    v-model="KRA.description"
                                    style="width: 100%; min-height: 70px;"
                                    :disabled="true"
                                    class="custom-formcontrol-none"
                                  />
                                  <small
                                    class="text-danger"
                                    style="font-family: Poppins"
                                  >{{ errors[0] }}</small>
                                </validation-provider>
                                <div class="d-flex flex-column">
                                  <transition-group
                                    name="list"
                                    mode="out-in"
                                  >
                                    <template>
                                      <div
                                        :key="`${KRA.id}+target`"
                                        class="mt-1 d-flex justify-content-between align-items-center"
                                      >
                                        <div
                                          style="background: #f7f7fd;
                                  border-radius: 6px; width: 100%;"
                                          class="p-1"
                                        >
                                          <!-- #KRA TARGET - START -->
                                          <template>
                                            <!-- replace everything in <template> with actual component -->
                                            <div>
                                              <comp-target
                                                :measurement.sync="KRA.target.measurement"
                                                :target.sync="KRA.target.target"
                                                :date.sync="KRA.target.due"
                                                :current-part="currentPart"
                                                :is-file-kra="KRA.target.isFileKra"
                                                :is-submitted="isSubmitted"
                                                :is-user-i-d="userId"
                                              />
                                            </div>
                                          </template>
                                        <!-- #KRA TARGET - END -->
                                        </div>
                                      </div>
                                    </template>
                                    <template v-if="isShowAchievement">
                                      <div
                                        :key="`${KRA.id}+achievement`"
                                        class="mt-1 d-flex justify-content-between align-items-center"
                                      >
                                        <div
                                          style="background: #f7f7fd;
                                          border-radius: 6px; width: 100%;"
                                          class="p-1"
                                        >
                                          <!-- #KRA ACHIEVEMENT - START -->
                                          <template>
                                            <!-- replace everything in <template> with actual component -->
                                            <div>
                                              <comp-achievement
                                                :tracking.sync="KRA.achievement.tracking"
                                                :achievement.sync="KRA.achievement.achievement"
                                                :yearachievement.sync="KRA.achievement.yearachievement"
                                                :attachment.sync="KRA.achievement.file"
                                                :resultattachment.sync="KRA.achievement.resultfile"
                                                :is-file="KRA.achievement.isFile"
                                                :is-file-result="KRA.achievement.isFileResult"
                                                :achievement_to_date.sync="KRA.achievement.achievement_to_date"
                                                :current-part="currentPart"
                                                :is-submitted="isSubmitted"
                                                :is-user-i-d="userId"
                                              />
                                            </div>

                                          </template>
                                        <!-- #KRA ACHIEVEMENT - END -->
                                        </div>
                                      </div>
                                    </template>
                                  </transition-group>
                                </div>
                              </div>
                              <!-- #REMOVE SPECIFIC KRA - START -->
                              <transition
                                name="zoom-out"
                                mode="out-in"
                              >
                                <template v-if="!isReadOnly && !isSubmitted && isSubmitted">
                                  <feather-icon
                                    icon="MinusCircleIcon"
                                    class="ml-2"
                                    style="color: #C5C7CD"
                                    size="35"
                                    @click="removeSpecificKRa(
                                      {
                                        id: scoreCard.id,
                                        index: indexScoreBoard
                                      },
                                      {
                                        id: KRA.id,
                                        index: indexKRA
                                      }
                                    )"
                                  />
                                </template>
                              </transition>
                            <!-- #REMOVE SPECIFIC KRA - END -->

                            </div>

                            <hr style="border-top: 1px solid #D5D5D5; width: 100%;">
                            <div class="d-flex">
                              <div
                                class="d-flex flex-column"
                                style="width: 100%;"
                              >
                                <span
                                  class="saj-text"
                                >{{ $t ("Remarks") }}</span>
                                <textarea
                                  v-model="KRA.remark"
                                  :disabled="true"
                                  style="width: 100%; min-height: 70px; margin-bottom: 10px; margin-top: 10px;"
                                  class="custom-formcontrol-none"
                                />
                              </div>
                              <transition
                                name="zoom-out"
                                mode="out-in"
                              >
                                <template v-if="isShowSelfScore">
                                  <div
                                    key="selfScore"
                                    class="ml-2 self-score"
                                  >
                                    <span style="margin-left: 0.3rem">{{ $t ("Self Score") }}:</span>
                                    <!-- {{ KRA }} tengok -->
                                    <b-form-rating
                                      v-model="KRA.self_score"
                                      :disabled="true"
                                      class="pl-0"
                                      no-border
                                      show-value
                                      inline
                                      color="#DFB300"
                                      stars="6"
                                      size="lg"
                                      style="background: none"
                                    />
                                    <br>
                                    <span style="margin-left: 0.3rem">{{ $t ("Manager Score") }}:</span>
                                    <b-form-rating
                                      v-model="KRA.manager_rating"
                                      show-clear
                                      class="pl-0"
                                      no-border
                                      show-value
                                      inline
                                      color="#DFB300"
                                      stars="6"
                                      size="lg"
                                      style="background: none"
                                      :disabled="disableRating"
                                    />
                                    <span style="margin-top: 5px; margin-right: 15px">
                                      <h5 v-if="KRA.manager_rating == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                      <h5 v-else-if="KRA.manager_rating == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                      <h5 v-else-if="KRA.manager_rating == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                                      <h5 v-else-if="KRA.manager_rating == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                                      <h5 v-else-if="KRA.manager_rating == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                      <h5 v-else-if="KRA.manager_rating == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                                    </span>
                                    <!-- <b-form-rating
                                      v-else
                                      v-model="KRA.manager_rating"
                                      show-clear
                                      disabled
                                      class="pl-0"
                                      no-border
                                      show-value
                                      inline
                                      color="#DFB300"
                                      stars="6"
                                      size="lg"
                                      style="background: none"
                                    /> -->
                                    <!-- @change="updateManagerScore(KRA.result.id, KRA.manager_rating)" -->
                                  </div>
                                </template>
                              </transition>

                            </div>
                          </div>
                        </b-collapse>
                      <!-- #KRA COLLAPSE PANEL - END -->
                      </div>
                    </transition-group>

                    <transition
                      name="zoom-out"
                      mode="out-in"
                    >
                      <template v-if="!isReadOnly && !isSubmitted && isSubmitted">
                        <div
                          key="addKRA"
                          class="mt-1 d-flex align-items-center justify-content-center"
                        >
                          <feather-icon
                            icon="PlusCircleIcon"
                            class="ml-2 mb-1"
                            style="color: #C5C7CD"
                            size="35"
                            @click="addKRA(
                              {
                                id: scoreCard.id,
                                index: indexScoreBoard
                              }
                            )"
                          />
                        </div>
                      </template>
                    </transition>
                  </div>

                </b-collapse>
              </validation-observer>
              <!-- #SCORE CARD COLLAPSE PANEL - END -->

            </div>
            <!-- START - If score boards empty -->
            <div
              v-if="scoreCards.length === 0"
              key="emptyScoreBoard"
            >
              <span style="color; #6E6B7B">No score card yet. Please create your score card. </span>
            </div>
            <!-- END - If score boards empty -->
          </transition-group>
        </template>
      </div>
    </div>
    <div v-else>
      <div
        class="saj-text d-flex align-items-center justify-content-center bg-white p-1 m-0 mb-3 mt-3"
        style="font-style: italic; background: rgb(247, 247, 252); text-align: center; height: 500px; border-radius:20px;  background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);"
      >

        {{ $t('Key Result Area are not available') }}

      </div>
    </div>
    <!-- END FORM -->
  </div>
</template>
<style lang="scss" scoped>
  .custom-formcontrol-none {
    // outline-color: red;
    border: 1px solid #d8d6de !important;
    text-overflow: ellipsis;
    background: '#FFFFFF';
    opacity: 0.9;
    backdrop-filter: blur(20px);
    width: 70%;
    height: 3.0rem;
    padding: 0.438rem 1rem;
    background-clip: padding-box;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  .custom-formcontrol-none:focus{
    outline: 0.01rem solid #ff0000 !important;
  }
.formTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  // border-bottom: 2.5px solid red;
  color: #FF0000;
}
</style>
<script>
import {
  BCollapse,
  BFormRating,
  VBTooltip,
  VBPopover,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue'
import CompTarget from '@/component/33-target.vue'
import CompAchievement from '@/component/34-achievement.vue'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from "vue-ripple-directive"
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BCollapse,
    CompTarget,
    CompAchievement,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BButton,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data(){
    return {
      isSuccessResult: false,
      performance_id: this.$route.params.performance_id,
      id: this.$route.params.id,
      isSupervisor: null,
      grade_set_id: null,
      subsidiary_id: null,
      kra_weightage: null,
      required,
      // resultId: 0,
      manager_rating: 0,
      allPart: [
        {
          title: 'Performance Planning',
          id: 'setKra',
        },
        {
          title: 'Performance Review',
          id: 'review',
        },
        {
          title: 'Year End Result',
          id: 'evaluation',
        },
      ],
      isSubmitted: false,
      currentPart: {
        title: null,
        id: null,
      },
      scoreCards: [
      ],
      total_score_exec: "30",
      total_score_top: "40",
      rating: [],
      approved_at: '',
      approved_sv_at: null,
      agree_at: null,
      approved_hod_at: null,
      submit_at: null,
      isApproveSHR: false,
      isApproveHOD: false,
      isApproveSV: false,
      disableRating: true,
    }
  },
  computed: {
    isShowSelfScore() {
      const showSelfScore = ['evaluation']
      return showSelfScore.includes(this.currentPart.id)
    },
    isReadOnly() {
      // const readOnly = ['review', 'evaluation']
      const readOnly = ['evaluation']
      return readOnly.includes(this.currentPart.id)
    },
    isShowAchievement() {
      const showAchievement = ['review', 'evaluation']
      return showAchievement.includes(this.currentPart.id)
    },
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    currentPart: {
      handler(part) {
        // console.log('part changes', part)
        this.whichPartNow(part)
      },
    },
    // scoreCards: {
    //   handler(newValue, oldValue) {
    //     console.log('part changes', newValue, oldValue)
    //     // newValue.forEach(a => {
    //     //   oldValue.forEach((b, idx) => {
    //     //     if (a.manager_rating !== b.manager_rating) {
    //     //       this.updateManagerScore(idx)
    //     //     }
    //     //   })
    //     // })
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.getScoreRating()
    // eslint-disable-next-line prefer-destructuring
    this.currentPart = this.allPart[0]
    // this.getResult()
    // this.currentUser()
    this.checkSupervisorStatus()
    if (this.$route.params.performance_id !== null){
      this.userId = this.$route.params.id.toString()
      this.currentUser()
    }
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    currentUser(){
      const data = new FormData()
      // data.append("user_id", this.idCore)
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.userId}`, data).then(response => {
        // console.log(response)
        this.subsidiary_id = response.data.data.employee.business_unit
        this.grade_set_id = response.data.data.grade_set_id
        // console.log(this.subsidiary_id, this.grade_set_id)
        this.weightage()
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.subsidiary_id)
      params.append('role_id', this.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.kra_weightage = response.data.data.key_result_area
        // console.log(this.kra_weightage)
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        // this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    toggleInput() {
      this.disableRating = !this.disableRating
    },
    whichPartNow(part = this.currentPart) {
      switch (part.id){
        case 'setKra':
          this.getPlanning()
          break
        case 'review':
          this.getReview()
          break
        case 'evaluation':
          this.getResult()
          break
        default:
      }
    },
    removeSpecificScoreBoard(scoreBoardIndex) {
      // this.scoreCards.splice(scoreBoardIndex.index, 1)
      const data = new FormData()
      data.append('objective_id', scoreBoardIndex.id)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/kra/delete_objective`,
        data,
      }

      this.$axios(config)
        .then(() => {
          this.whichPartNow()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.scoreCards.splice(scoreBoardIndex.index, 1)
        })
    },
    removeSpecificKRa(scoreCard, KRAItem) {
      // this.scoreCards[scoreCard.index].KRA.splice(KRAItem.index, 1)
      const data = new FormData()
      data.append('kra_id', KRAItem.id)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/kra/delete_kra`,
        data,
      }

      this.$axios(config)
        .then(() => {
          this.whichPartNow()
        })
        .catch(() => {
          this.scoreCards[scoreCard.index].KRA.splice(KRAItem.index, 1)
          // console.log(error.response)
        })
    },
    removeSpecificTarget(scoreCard, KRA, target) {
      this.scoreCards[scoreCard.index].KRA[KRA.index].target.splice(target.index, 1)
    },
    addKRA(scoreCard) {
      const newKRA = {
        id: `KRA${this.generateRandomString()}`,
        visible: false,
        description: null,
        remark: null,
        self_score: 0,
        target: {
          id: `target${this.generateRandomString()}`,
          measurement: null,
          target: null,
          due: null,
        },
        achievement: {
          tracking: null,
          achievement: null,
          file: null,
        },
      }
      this.scoreCards[scoreCard.index].KRA.push(newKRA)
    },
    generateRandomString() {
      return (+new Date() * Math.random()).toString(36).substring(0, 6)
    },
    addScoreBoard() {
      const newScoreBoard = {
        id: `ScoreBoard${this.generateRandomString()}`,
        visible: false,
        title: 'Objective / Initiative',
        description: null,
        KRA: [
          {
            id: `KRA${this.generateRandomString()}`,
            visible: false,
            description: null,
            remark: null,
            self_score: 0,
            target: {
              id: `target${this.generateRandomString()}`,
              visible: false,
              measurement: null,
              target: null,
              due: null,
            },
            achievement: {
              tracking: null,
              achievement: null,
              file: null,
            },
          },
        ],
      }

      this.scoreCards.push(newScoreBoard)
    },
    getPlanning() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_planning?user_id=${this.userId}`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          // console.log('res', this.userId)
          if (result.success) {
            const AllObjective = result.data.objective
            // console.log('Planning objective', AllObjective)
            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                // console.log('response', allKRA)
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })
                // eslint-disable-next-line no-unused-vars
                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.target
                  tempScoreCards[index].KRA.push(
                    {
                      id: itemKRA.kra_id,
                      visible: false,
                      description: itemKRA.kra_name,
                      remark: currentState.remarks,
                      target: {
                        id: currentState.id,
                        visible: false,
                        measurement: currentState.measurement,
                        target: currentState.target,
                        due: currentState.due_date,
                        isFileKra: currentState.file,
                      },
                      achievement: {
                        tracking: null,
                        achievement: null,
                        file: null,
                        self_score: null,
                      },

                      // result: {
                      //   id: currentState.id,

                      // },
                    },
                  )
                })
              })

              this.scoreCards = tempScoreCards
            } else {
              this.scoreCards = []
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          // console.log(error.response.data)
        })
    },
    getReview() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_review?user_id=${this.userId}`,
      }
      // console.log("get review")
      this.$axios(config)
        .then(response => {
          // console.log("success review", response)
          const result = response.data
          if (result.success) {
            const AllObjective = result.data.objective
            // console.log('Review objective', AllObjective)

            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })

                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.achievement
                  let visibleKra = false
                  if (this.scoreCards[index].KRA[idx] !== undefined){
                    const previousKra = _.cloneDeep(this.scoreCards[index].KRA[idx])
                    if (this.scoreCards.length > 0) {
                      visibleKra = previousKra.visible === undefined ? false : previousKra.visible
                    }
                  }

                  tempScoreCards[index].KRA.push(
                    {
                      id: itemKRA.kra_id,
                      visible: visibleKra,
                      description: itemKRA.kra_name,
                      remark: currentState.remarks,
                      self_score: currentState.rating === null ? 0 : currentState.rating,
                      target: {
                        id: currentState.id,
                        visible: false,
                        measurement: currentState.measurement,
                        target: currentState.target,
                        due: currentState.due_date,
                      },
                      achievement: {
                        tracking: currentState.tracking,
                        achievement: currentState.achievement,
                        file: null,
                        isFile: currentState.file,
                        achievement_to_date: currentState.achievement_to_date,
                      },
                    },
                  )
                })
              })

              this.scoreCards = tempScoreCards
            } else {
              this.scoreCards = []
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        // .catch(error => {
        //   const res = error.response
        //   // eslint-disable-next-line no-unused-vars
        //   const resData = error.response.data
        //   if (res.status === 404) {
        //     // eslint-disable-next-line prefer-destructuring
        //     this.currentPart = this.allPart[0]
        //     // alert(resData.message)
        //   }
        // })
    },
    getResult() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_result?user_id=${this.userId}&cycle_id=${this.performance_id}`,
      }

      // console.log('get result')

      this.$axios(config)
        .then(response => {
          const result = response.data
          // console.log('result', response)
          if (result.success) {
            // console.log('success ya')
            const AllObjective = result.data.objective
            // console.log('result objective', AllObjective)
            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })

                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.result
                  let visibleKra = false
                  // this.resultId = currentState.id
                  if (this.scoreCards[index].KRA[idx] !== undefined){
                    const previousKra = _.cloneDeep(this.scoreCards[index].KRA[idx])
                    if (this.scoreCards.length > 0) {
                      visibleKra = previousKra.visible === undefined ? false : previousKra.visible
                    }
                  }
                  // console.log('current state', currentState)
                  tempScoreCards[index].KRA.push(
                    {
                      id: itemKRA.kra_id,
                      visible: visibleKra,
                      remark: currentState.remarks,
                      description: itemKRA.kra_name,
                      self_score: currentState.rating === null ? 0 : currentState.rating,
                      manager_rating: currentState.manager_rating,
                      target: {
                        id: currentState.id,
                        visible: false,
                        measurement: currentState.measurement,
                        target: currentState.target,
                        due: currentState.due_date,
                      },
                      achievement: {
                        tracking: currentState.tracking,
                        achievement: currentState.achievement,
                        yearachievement: currentState.achievement,
                        resultfile: null,
                        isFileResult: currentState.file,
                      },
                      result: {
                        id: currentState.id,
                      },
                    },
                  )
                })
              })

              this.scoreCards = tempScoreCards

              this.approved_at = response.data.data.approved_at
              this.approved_sv_at = response.data.data.approved_sv_at
              this.agree_at = response.data.data.agree_at
              this.approved_hod_at = response.data.data.approved_hod_at
              this.submit_at = response.data.data.submit_at
              // console.log(this.approved_at)

              switch (this.roles.selectedRole){
                case 1:
                  // Admin
                  // console.log('admin')
                  if (this.agree_at !== null && this.approved_at === null){
                    // console.log('masuk admin')
                    this.isApproveSHR = true
                  }
                  break
                case 3:
                  // Executive
                  // console.log('Executive')
                  if (this.submit_at !== null){
                    if (this.agree_at === null){
                      // console.log('masuk exec', this.agree_at, this.submit_at)
                      this.isApproveSV = true
                    }
                  }
                  break
                case 4:
                  // Top Management
                  // console.log('Top Management')
                  if (this.submit_at !== null){
                    if (this.agree_at === null){
                      // console.log('masuk exec', this.agree_at, this.submit_at)
                      this.isApproveSV = true
                    }
                  }
                  break
                case 5:
                  // Head of Department
                  // console.log('Head of Department')
                  if (this.agree_at !== null && this.approved_hod_at === null){
                    // console.log('masuk hod')
                    this.isApproveHOD = true
                  }
                  break
                default:
                  break
              }
            } else {
              this.scoreCards = []
            }
          }
          // console.log('success tak')
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          // this.isSubmitted = true
        })
    },
    checkIfNull(val) {
      if (val !== null && val !== undefined) {
        return val
      }
      return ''
    },
    saveKra() {
      const validateKRALength = this.$refs.validateKRA.length
      this.$refs.validateKRA.forEach((item, index) => {
        this.$refs.validateKRA[index].validate().then(success => {
          if (index === validateKRALength - 1) {
            if (success){
              if (this.scoreCards.length > 0) {
                const data = new FormData()
                const toSubmitPlanning = []

                // eslint-disable-next-line no-shadow
                this.scoreCards.forEach((card, index) => {
                  toSubmitPlanning.push({
                    objective_id: card.id,
                    objective_name: card.description,
                    kra: [],
                  })

                  const allKra = card.KRA

                  allKra.forEach(itemKRA => {
                    const currentState = itemKRA.target
                    toSubmitPlanning[index].kra.push(
                      {
                        kra_id: itemKRA.id,
                        kra_name: itemKRA.description,
                        type: "planning",
                        target: {
                          target_id: this.checkIfNull(currentState.id),
                          measurement: this.checkIfNull(currentState.measurement),
                          target: this.checkIfNull(currentState.target),
                          due_date: this.checkIfNull(currentState.due),
                          remarks: this.checkIfNull(itemKRA.remark),
                        },
                      },
                    )
                  })
                })

                const dataToString = JSON.stringify(toSubmitPlanning)

                data.append('input', dataToString)

                // eslint-disable-next-line no-unused-vars
                const config = {
                  method: 'post',
                  url: `${this.$baseUrl}/kra/store_planning`,
                  data,
                }

                this.$axios(config)
                  .then(() => {
                    this.whichPartNow()
                  })
                  // .catch(error => {
                  //   const res = error.response
                  //   const resData = error.response.data
                  //   if (res.status === 404) {
                  //     alert(resData.message)
                  //   }
                  // })
              }
            } else {
              // eslint-disable-next-line no-alert
              alert('Please fill all required fields')
            }
          }
        })
      })
    },
    saveReview() {
      const validateKRALength = this.$refs.validateKRA.length
      this.$refs.validateKRA.forEach((item, index) => {
        this.$refs.validateKRA[index].validate().then(success => {
          if (index === validateKRALength - 1) {
            if (success){
              this.scoreCards.forEach(card => {
                const allKra = card.KRA

                allKra.forEach(itemKRA => {
                  const { achievement } = itemKRA
                  const { target } = itemKRA

                  const data = new FormData()
                  data.append('kra_id[]', itemKRA.id)
                  if (card.id !== undefined && card.id !== null) {
                    data.append(`objective_id[${itemKRA.id}]`, card.id)
                  }
                  if (itemKRA.description !== undefined && itemKRA.description !== null) {
                    data.append(`kra_name[${itemKRA.id}]`, itemKRA.description)
                  }
                  if (achievement.achievement !== undefined && achievement.achievement !== null) {
                    data.append(`achievement[${itemKRA.id}]`, achievement.achievement)
                  }
                  if (achievement.achievement_to_date !== undefined && achievement.achievement_to_date !== null) {
                    data.append(`achievement_to_date[${itemKRA.id}]`, achievement.achievement_to_date)
                  }
                  if (achievement.tracking !== undefined && achievement.tracking !== null) {
                    data.append(`tracking[${itemKRA.id}]`, achievement.tracking)
                  }
                  if (itemKRA.remark !== undefined && itemKRA.remark !== null) {
                    data.append(`remarks[${itemKRA.id}]`, itemKRA.remark)
                  }
                  if (Number(itemKRA.self_score) !== undefined && Number(itemKRA.self_score) !== null) {
                    data.append(`rating[${itemKRA.id}]`, Number(itemKRA.self_score))
                  }
                  if (target.measurement !== undefined && target.measurement !== null) {
                    data.append(`measurement[${itemKRA.id}]`, target.measurement)
                  }
                  if (target.target !== undefined && target.target !== null) {
                    data.append(`target[${itemKRA.id}]`, target.target)
                  }
                  if (target.due !== undefined && target.due !== null) {
                    data.append(`due_date[${itemKRA.id}]`, target.due)
                  }
                  if (achievement.file instanceof File) {
                    data.append(`file[${itemKRA.id}]`, achievement.file)
                  }

                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/store_achievement`,
                    data,
                  }

                  this.$axios(config)
                    .then(response => {
                      const result = response.data
                      if (result.success) {
                        this.whichPartNow()
                      } else {
                        // eslint-disable-next-line no-alert
                        alert(result.message)
                      }
                    })
                  // .catch(error => {
                  //   const res = error.response
                  //   const resData = error.response.data
                  //   console.log('sini', res)
                  //   if (res.status === 404) {
                  //     alert(resData.message)
                  //   }
                  // })
                })
              })
            } else {
              // eslint-disable-next-line no-alert
              alert('Please fill all required fields')
            }
          }
        })
      })
    },
    saveResult() {
      const allScoreCardPromisesResult = Promise.all(this.scoreCards.map(card => {
        const allKra = card.KRA

        const allKraPromisesResult = Promise.all(allKra.map(itemKRA => {
          const { achievement } = itemKRA
          const data = new FormData()
          data.append('kra_id[]', itemKRA.id)
          if (card.id !== undefined && card.id !== null) {
            data.append(`objective_id[${itemKRA.id}]`, card.id)
          }
          data.append('objective_name', card.description)
          if (achievement.achievement !== null && achievement.achievement !== undefined) {
            data.append(`achievement[${itemKRA.id}]`, achievement.achievement)
          }
          if (achievement.tracking !== null && achievement.tracking !== undefined) {
            data.append(`tracking[${itemKRA.id}]`, achievement.tracking)
          }
          if (itemKRA.remark !== null && itemKRA.remark !== undefined) {
            data.append(`remarks[${itemKRA.id}]`, itemKRA.remark)
          }
          if (Number(itemKRA.self_score) !== null && Number(itemKRA.self_score) !== undefined) {
            data.append(`rating[${itemKRA.id}]`, Number(itemKRA.self_score))
          }
          this.updateManagerScore(itemKRA.result.id, itemKRA.manager_rating)

          const config = {
            method: 'post',
            url: `${this.$baseUrl}/kra/store_result`,
            data,
          }

          return this.$axios(config)
            .then(response => {
              const result = response.data
              if (result.success) {
                this.whichPartNow()
              }
              return response
            })
        }))
        return allKraPromisesResult
      }))
      allScoreCardPromisesResult.then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Manager score for KRA successfully saved')}!`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    submitResult() {
      const config = {
        method: 'post',
        url: `${this.$baseUrl}/kra/submit_result`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.whichPartNow()
          } else {
            // eslint-disable-next-line no-alert
            alert(result.message)
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },
    submitKra() {
      const validateKRALength = this.$refs.validateKRA.length
      this.$refs.validateKRA.forEach((item, index) => {
        this.$refs.validateKRA[index].validate().then(success => {
          if (index === validateKRALength - 1) {
            if (success){
              const config = {
                method: 'post',
                url: `${this.$baseUrl}/kra/submit_planning`,
              }

              this.$axios(config)
                .then(() => {
                  this.whichPartNow()
                })
                .catch(() => {
                  // console.log(error)
                })
            } else {
              // eslint-disable-next-line no-alert
              alert('Please fill all required fields')
            }
          }
        })
      })
      // const validateKRALength = this.$refs.validateKRA.length
      // this.$refs.validateKRA.forEach((item, index) => {
      //   this.$refs.validateKRA[index].validate().then(success => {
      //     if (index === validateKRALength - 1) {
      //       if (success){
      //         //
      //       } else {
      //         alert('Please fill all required fields')
      //       }
      //     }
      //   })
      // })
    },
    updateManagerScore(id, ratingScore){
      // console.log('manager score', this.userId, this.scoreCards[0].KRA[0].result.id, this.scoreCards[0].KRA[0].manager_rating)

      const data = new FormData()
      data.append('user_id', this.userId)
      data.append('result_id', id)
      data.append('manager_rating', ratingScore)
      data.append(`current_role`, this.roles.selectedRole)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/manager/kra_manager_score_store`,

        data,
      }

      this.$axios(config)
        .then(() => {
          // console.log('success')
          this.whichPartNow('setKra')
          // this.$toast(
          //   {
          //     component: SAJToast,
          //     props: {
          //       title: `${this.$t('Manager Score successfully updated')}!`,
          //       icon: 'CheckCircleIcon',
          //       variant: 'success',
          //       titleColor: '#000',
          //     },
          //   },
          //   {
          //     position: "top-right",
          //     type: 'info',
          //   },
          // )
        })
        .catch(() => {
          // console.log(error.message)
        })
    },
    submitReview() {
      const validateKRALength = this.$refs.validateKRA.length
      this.$refs.validateKRA.forEach((item, index) => {
        this.$refs.validateKRA[index].validate().then(success => {
          if (index === validateKRALength - 1) {
            if (success){
              const config = {
                method: 'post',
                url: `${this.$baseUrl}/kra/submit_review`,
              }

              this.$axios(config)
                .then(response => {
                  const result = response.data
                  if (result.success) {
                    this.whichPartNow()
                  } else {
                    // eslint-disable-next-line no-alert
                    alert(result.message)
                  }
                })
                .catch(() => {
                  // console.log(error)
                })
            } else {
              // eslint-disable-next-line no-alert
              alert('Please fill all required fields')
            }
          }
        })
      })
    },

  },
}
</script>
